.addEvent {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(20, 20, 20, 80%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addEvent h2 {
    text-align: center;
}

.addEvent>div {
    background-color: rgba(20, 20, 20, 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 50px;
    width: 75%;
}

.addEvent form>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
}

@media (min-width: 900px) {
    .addEvent>div {
        padding: 50px 100px;
        width: 80%;
    }
}