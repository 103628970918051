.scheduleContainer {
    padding: 25px;
}

.schedule {
    border-collapse: collapse;
    border-color: rgb(20, 20, 20);
    user-select: none;
}

/* Days header row */

.schedule thead tr th {
    padding-bottom: 25px;
    border-bottom: 2px solid;
    /* Use day display view for mobile */
    display: none;
}

/* Set height for each half hour chunk */

.schedule tbody tr {
    height: 30px;
}

.schedule tbody tr td {
    cursor: pointer;
}

/* So that event elements can be positioned correctly */

.schedule tbody tr td {
    position: relative;
}

/* Fit time column to content */

.schedule thead tr td, .schedule tbody tr th {
    padding-right: 25px;
    width: 1%;
    white-space: nowrap;
    text-align: right;
}

/* Time column & day column divider */

.schedule tbody tr th, .schedule tbody tr td {
    border-right: 2px solid;
}

/* Day half hour row divider */

.schedule tbody tr:nth-child(n) td {
    border-bottom: 2px dotted;
}

/* Day hour row divider */

.schedule tbody tr:nth-child(2n) td {
    border-bottom: 2px solid;
}

.schedule tbody tr td:hover {
    background-color: rgb(66, 66, 66);
}

.schedule tbody tr:first-child td {
    border-top: 2px solid;
}

@media (min-width: 900px) {
    /* Make day columns consistent width */
    .schedule thead tr th {
        display: table-cell;
    }
    .schedule thead tr th, .schedule tbody tr td {
        /* 100/7 */
        width: 14.25%;
    }
}