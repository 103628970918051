.appContainer, .appContainer main {
    display: flex;
    flex-direction: column;
}

.appContainer main {
    background-color: rgb(50, 50, 55);
    min-height: 100vh;
}

@media (min-width: 900px) {
    .appContainer {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .appContainer main {
        flex: 1;
    }
}

.appContainer form ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.appContainer form ul li {
    display: flex;
    flex-direction: column;
}

.appContainer form ul li textarea {
    min-height: 25vh;
}

.appContainer form ul li label {
    margin-bottom: 5px;
}

.appContainer form ul li:not(:first-child) label {
    margin-top: 10px;
}

.appContainer form ul li:last-child {
    margin-bottom: 10px;
}

.appContainer form ul li select, .appContainer form ul li input {
    padding: 5px;
}

.appContainer form>div button:first-of-type {
    margin-right: 10px;
}