@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    background-color: rgb(32, 33, 36);
    color: rgb(214, 214, 214);
    margin: 0;
    padding: 0;
}

a {
    color: rgb(214, 214, 214);
}

a:hover, a:active, a:focus {
    color: rgb(190, 190, 190);
}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
}

input, select, textarea {
    padding: 4px;
}

button, .button {
    background-color: rgb(35, 35, 35);
    color: rgb(214, 214, 214);
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
}

button.delete, .button.delete {
    background-color: rgb(133, 43, 43);
}

/* 
    Toggle switch
    Adapted from: https://danklammer.com/articles/simple-css-toggle-switch/
 */

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    height: 20px;
    display: inline-block;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgb(50, 50, 55);
    transition: background-color ease 0.3s;
    margin: 0;
    padding: 0;
}

/* Toggle switch knob */

input[type="checkbox"]:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    content: "";
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

/* Selected toggle color */

input[type="checkbox"]:checked {
    background-color: rgb(214, 214, 214);
}

/* Selected toggle knob */

input[type="checkbox"]:checked:before {
    left: 20px;
}

.hidden {
    display: none;
}