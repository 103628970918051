.displayControls {
    display: none;
}

.dayControls {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
}

.dayControls h2, .dayControls span {
    flex: 1;
}

.dayControls span {
    font-size: 1.5rem;
}

.dayControls a {
    user-select: none;
    text-decoration: none;
    font-size: 2rem;
}

@media (min-width: 900px) {
    /* Use week display view for larger screen */
    .displayControls {
        display: flex;
    }
    .displayControls button {
        flex: 1;
    }
}