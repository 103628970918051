.footer {
    background-color: rgb(20, 20, 20);
    padding: 25px;
}

@media (min-width: 600px) {
    .footer {
        text-align: center;
    }
}

@media (min-width: 900px) {
    .footer {
        flex-basis: 100%;
    }
}