.event {
    background-color: white;
    color: black;
    text-align: center;
    overflow: hidden;
    /* Fix td hover */
    z-index: 1;
    /* Fit to parent td */
    width: 100%;
    /* Will be positioned absolutely relative to parent td */
    position: absolute;
    left: 0;
    border: 2.5px solid;
    border-top: 10px solid;
    cursor: pointer;
}

.event h2 {
    font-size: 1rem;
}

.event h3 {
    font-size: 0.9rem;
}