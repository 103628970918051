@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
.Header_header__2jk7M {
    text-align: center;
    padding: 25px 0;
    margin: 0 auto;
}

.Header_header__2jk7M img {
    width: 20%;
}

.Header_header__2jk7M nav ul {
    padding: 0;
    list-style: none;
}

.Header_header__2jk7M nav ul li {
    margin: 10px 0;
}

.Header_header__2jk7M nav ul a {
    text-decoration: none;
}

.Header_active__1wNyP>a {
    font-weight: bold;
}

.Header_active__1wNyP ul {
    list-style: none;
    padding: 0;
}

.Header_active__1wNyP ul li {
    display: flex;
    justify-content: center;
}

.Header_active__1wNyP ul li:not(:first-child) {
    margin-top: 10px;
}

.Header_active__1wNyP ul li label {
    margin-right: 10px;
}

.Header_selected__2n3Zu>a {
    text-decoration: underline;
}

@media (min-width: 900px) {
    .Header_header__2jk7M {
        flex: 0 0 300px;
    }
}
.Splash_splash__1Dope {
    display: flex;
    justify-content: center;
}

.Splash_splash__1Dope img {
    width: 5rem;
}

.Splash_splash__1Dope button {
    margin-top: 25px;
}

.Splash_splash__1Dope>section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Splash_splash__1Dope>section:first-child {
    padding: 50px;
}

.Splash_splash__1Dope>section:last-child {
    display: none;
}

@media (min-width: 600px) {
    .Splash_splash__1Dope {
        flex-direction: row;
    }
    .Splash_splash__1Dope img {
        width: 7.5rem;
    }
    .Splash_splash__1Dope>section {
        align-items: center;
        flex: 1 1;
    }
    .Splash_splash__1Dope>section:first-child h1 {
        font-size: 1.5rem;
    }
    .Splash_splash__1Dope>section:first-child p {
        font-size: 1rem;
        width: 25rem;
    }
    .Splash_splash__1Dope>section:last-child {
        background-color: rgb(70, 70, 75);
        display: flex;
        flex-direction: column;
    }
    .Splash_splash__1Dope>section:last-child>div {
        width: 100%;
        height: 33.33vh;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .Splash_splash__1Dope>section:last-child>div:first-child {
        background-image: url(/static/media/emma-matthews-digital-content-production-8K62atzbulQ-unsplash.77e9b3a4.jpg);
    }
    .Splash_splash__1Dope>section:last-child>div:nth-child(2) {
        background-image: url(/static/media/jessica-lewis-fJXv46LT7Xk-unsplash.796e92cf.jpg);
    }
    .Splash_splash__1Dope>section:last-child>div:last-child {
        background-image: url(/static/media/emma-matthews-digital-content-production-MG_yBBC0v50-unsplash.367f5630.jpg);
    }
}
.Controls_displayControls__1lctt {
    display: none;
}

.Controls_dayControls__2JWnc {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
}

.Controls_dayControls__2JWnc h2, .Controls_dayControls__2JWnc span {
    flex: 1 1;
}

.Controls_dayControls__2JWnc span {
    font-size: 1.5rem;
}

.Controls_dayControls__2JWnc a {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    font-size: 2rem;
}

@media (min-width: 900px) {
    /* Use week display view for larger screen */
    .Controls_displayControls__1lctt {
        display: flex;
    }
    .Controls_displayControls__1lctt button {
        flex: 1 1;
    }
}
.Event_event__g9866 {
    background-color: white;
    color: black;
    text-align: center;
    overflow: hidden;
    /* Fix td hover */
    z-index: 1;
    /* Fit to parent td */
    width: 100%;
    /* Will be positioned absolutely relative to parent td */
    position: absolute;
    left: 0;
    border: 2.5px solid;
    border-top: 10px solid;
    cursor: pointer;
}

.Event_event__g9866 h2 {
    font-size: 1rem;
}

.Event_event__g9866 h3 {
    font-size: 0.9rem;
}
.ScheduleTable_scheduleContainer__20F01 {
    padding: 25px;
}

.ScheduleTable_schedule__ypqem {
    border-collapse: collapse;
    border-color: rgb(20, 20, 20);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* Days header row */

.ScheduleTable_schedule__ypqem thead tr th {
    padding-bottom: 25px;
    border-bottom: 2px solid;
    /* Use day display view for mobile */
    display: none;
}

/* Set height for each half hour chunk */

.ScheduleTable_schedule__ypqem tbody tr {
    height: 30px;
}

.ScheduleTable_schedule__ypqem tbody tr td {
    cursor: pointer;
}

/* So that event elements can be positioned correctly */

.ScheduleTable_schedule__ypqem tbody tr td {
    position: relative;
}

/* Fit time column to content */

.ScheduleTable_schedule__ypqem thead tr td, .ScheduleTable_schedule__ypqem tbody tr th {
    padding-right: 25px;
    width: 1%;
    white-space: nowrap;
    text-align: right;
}

/* Time column & day column divider */

.ScheduleTable_schedule__ypqem tbody tr th, .ScheduleTable_schedule__ypqem tbody tr td {
    border-right: 2px solid;
}

/* Day half hour row divider */

.ScheduleTable_schedule__ypqem tbody tr:nth-child(n) td {
    border-bottom: 2px dotted;
}

/* Day hour row divider */

.ScheduleTable_schedule__ypqem tbody tr:nth-child(2n) td {
    border-bottom: 2px solid;
}

.ScheduleTable_schedule__ypqem tbody tr td:hover {
    background-color: rgb(66, 66, 66);
}

.ScheduleTable_schedule__ypqem tbody tr:first-child td {
    border-top: 2px solid;
}

@media (min-width: 900px) {
    /* Make day columns consistent width */
    .ScheduleTable_schedule__ypqem thead tr th {
        display: table-cell;
    }
    .ScheduleTable_schedule__ypqem thead tr th, .ScheduleTable_schedule__ypqem tbody tr td {
        /* 100/7 */
        width: 14.25%;
    }
}
.AddEvent_addEvent__3Ugd6 {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(20, 20, 20, 80%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AddEvent_addEvent__3Ugd6 h2 {
    text-align: center;
}

.AddEvent_addEvent__3Ugd6>div {
    background-color: rgba(20, 20, 20, 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 50px;
    width: 75%;
}

.AddEvent_addEvent__3Ugd6 form>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
}

@media (min-width: 900px) {
    .AddEvent_addEvent__3Ugd6>div {
        padding: 50px 100px;
        width: 80%;
    }
}
.ManageSchedules_manageView__sF9Jg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ManageSchedules_manageView__sF9Jg>div {
    width: 100%;
    padding: 25px;
}

@media (min-width: 900px) {
    .ManageSchedules_manageView__sF9Jg>div {
        padding: 50px 100px 100px;
    }
}
.Footer_footer__IZ0NP {
    background-color: rgb(20, 20, 20);
    padding: 25px;
}

@media (min-width: 600px) {
    .Footer_footer__IZ0NP {
        text-align: center;
    }
}

@media (min-width: 900px) {
    .Footer_footer__IZ0NP {
        flex-basis: 100%;
    }
}
.App_appContainer__1sKo-, .App_appContainer__1sKo- main {
    display: flex;
    flex-direction: column;
}

.App_appContainer__1sKo- main {
    background-color: rgb(50, 50, 55);
    min-height: 100vh;
}

@media (min-width: 900px) {
    .App_appContainer__1sKo- {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .App_appContainer__1sKo- main {
        flex: 1 1;
    }
}

.App_appContainer__1sKo- form ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.App_appContainer__1sKo- form ul li {
    display: flex;
    flex-direction: column;
}

.App_appContainer__1sKo- form ul li textarea {
    min-height: 25vh;
}

.App_appContainer__1sKo- form ul li label {
    margin-bottom: 5px;
}

.App_appContainer__1sKo- form ul li:not(:first-child) label {
    margin-top: 10px;
}

.App_appContainer__1sKo- form ul li:last-child {
    margin-bottom: 10px;
}

.App_appContainer__1sKo- form ul li select, .App_appContainer__1sKo- form ul li input {
    padding: 5px;
}

.App_appContainer__1sKo- form>div button:first-of-type {
    margin-right: 10px;
}
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    background-color: rgb(32, 33, 36);
    color: rgb(214, 214, 214);
    margin: 0;
    padding: 0;
}

a {
    color: rgb(214, 214, 214);
}

a:hover, a:active, a:focus {
    color: rgb(190, 190, 190);
}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
}

input, select, textarea {
    padding: 4px;
}

button, .button {
    background-color: rgb(35, 35, 35);
    color: rgb(214, 214, 214);
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
}

button.delete, .button.delete {
    background-color: rgb(133, 43, 43);
}

/* 
    Toggle switch
    Adapted from: https://danklammer.com/articles/simple-css-toggle-switch/
 */

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    height: 20px;
    display: inline-block;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgb(50, 50, 55);
    transition: background-color ease 0.3s;
    margin: 0;
    padding: 0;
}

/* Toggle switch knob */

input[type="checkbox"]:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    content: "";
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

/* Selected toggle color */

input[type="checkbox"]:checked {
    background-color: rgb(214, 214, 214);
}

/* Selected toggle knob */

input[type="checkbox"]:checked:before {
    left: 20px;
}

.hidden {
    display: none;
}
